import React, { useEffect, useState } from "react";
import "./sale-banner.scss";
import EditorContent from "../../components/EditorContent/EditorContent";

const SaleBanner = ({data}) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const end = new Date();
    end.setHours(23,59,59,999);

    let saleEnd = new Date(data?.date || true);
    saleEnd.setHours(23,59,59,999);

    
    if(saleEnd < end) saleEnd = end;
    
    const getTime = () => {
      const time = Date.parse(saleEnd) - Date.now();    
      setHours(Math.floor((time / (1000 * 60 * 60))));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
  
    useEffect(() => {
      const interval = setInterval(() => getTime(end), 1000);
  
      return () => clearInterval(interval);
    }, []);

  if(data?.hideSection) return null;

  return (
    <div className="sale-banner-container">
      <div className="sale-text"><EditorContent content={data?.content} style={{whiteSpace: 'initial'}} noBg /></div>
      <div className="sale-vertical-line"></div>
      <div className="timer-container">
        <div className="timer-text">Ending in</div>
        <div className="digit flex">{hours < 10 ? "0" + hours : hours} <span className="timer-text">Hours</span></div>
        <div className="timer-dots bold">:</div>
        <div className="digit flex">{minutes < 10 ? "0" + minutes : minutes} <span className="timer-text">Mins</span></div>
        <div className="timer-dots bold">:</div>
        <div className="digit flex">{seconds < 10 ? "0" + seconds : seconds} <span className="timer-text">Sec</span></div>
      </div>
    </div>
  );
};

export default SaleBanner;
