import React from "react";
import "./blog-card.scss";

import { Link } from "react-router-dom";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M9.62004 4.45337L13.6667 8.50004L9.62004 12.5467M2.33337 8.50004H13.5534" stroke="#D5AC4F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
  };

const BlogCard = (({title,label, labelBg, author,readTime,cover}) => {
    return (
      <div className="blog-card-box">
        <div className="blog-img">
          <img src={cover} />
        </div>
        <div className="blog-info-container">
          <div className="blog-label" style={{background: labelBg}}>{label}</div>
          <div className="blog-title">{title}</div>
          <div className="blog-author-read">
            <div className="blog-author">by <span style={{fontWeight: '600'}}>{author}</span></div>
            <div className="dot" />
            <div>{readTime} min read</div>
          </div>
        </div>
      </div>
    );
}
);

export default BlogCard;