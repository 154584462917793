import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "./reviews-section.scss";
import reviewPerson1 from "../../assets/images/review1.jpeg";
import reviewPerson2 from "../../assets/images/review1.png";
import reviewPerson3 from "../../assets/images/no-photo.png";


import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import ReviewCard from "./ReviewCard";

const data = [
  {
    msg: "This finish and quality of the switchboards is amazing, ordering for my other rooms too. Alexa integration is lit 👍",
    star: 5,
    name: "Bilal Mazhar",
    date: "5 Aug, 2023",
    img: reviewPerson1,
    title: ""
  },
  {
    msg: "Call support is the best part. I am sure this will be in every household. Water pump timer, super product!",
    star: 5,
    name: "Alina Syed",
    date: "25 Aug, 2023",
    img: reviewPerson2,
    title: "Property Advisor"
  },
  {
    msg: "Woww! this tech is now in Pakistan.. Lovely using it, last i used it in Dubai and now the similar experience in affordable prices in Pakistan. Brilliant job team Pintar 👍👍 Just make sure you keep giving support like now.",
    star: 5,
    name: "Danyal Wazir",
    date: "28 July, 2023",
    img: reviewPerson3,
    title: ""
  },
];

// {headingProps, data = [...Array(6).keys()]}

const ReviewsSection = () => {
  // We will use React useRef hook to reference the wrapping div:
  const containerRef = useRef(null);
  // Now we pass the reference to the useDraggable hook:
  const { events } = useDraggable(containerRef, {isMounted: true});

  return (
    <div className="blogs-section-container">
      <SectionHeading preText={'🙂 Kind Words From Our '} boldText='Customers!' reviewSection />
      <div className="blogs-cards-section" {...events} ref={containerRef}>
      {
        // repeat items just to fill container and make its content overflow
        data.map((review, i) => (
          <ReviewCard {...review} key={i} />
        ))
      }
    </div>
    </div>
  );
};

export default ReviewsSection;
