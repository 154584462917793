import React from "react";
import "./filter-card.scss";
import { ReactComponent as Star } from "../../../assets/Icons/rate-star.svg";
import { Link } from "react-router-dom";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M9.62004 4.45337L13.6667 8.50004L9.62004 12.5467M2.33337 8.50004H13.5534" stroke="#D5AC4F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
  }


const FilterCard = (({title, bgColor, img, filterId}) => {
    return (
      <div className="filter-card-box">
        <div className="img-container"  style={{background: bgColor}}>
          <img src={img} />
        </div>
        <div className="info-container">
          <div className="filter-product-title">{title}</div>
          <div className="filter-view-item">
            <Link to={title === "Smart RGB Lighting" ? "/shop/pFAxb6pdGq6WHsZKAykP" : `/shop/6gkSICnXAzNBlQWPjPuZ?filter=${filterId}`}>
              View All {<Icon />}
            </Link>
          </div>
        </div>
      </div>
    );
}
);

export default FilterCard;