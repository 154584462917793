import React, { useEffect, lazy, Suspense } from "react";

import Landing from "../../components/HomePage/Landing";
// import KeyFeatures from "../../components/HomePage/KeyFeatures";
// import ContactUs from "../../components/HomePage/ContactUs";
// import Footer from "../../components/Footer";
// import Products from "../../components/HomePage/Products";
// import Services from "../../components/HomePage/Services";
// import Video from "../../components/HomePage/Video";
// import Info from "../../components/HomePage/Info";
import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { getBanners, getBestSellingProducts, getHotSellingProducts, getNewLaunchingProducts, getSaleBannerData } from "./Home.service";
import "./home-page.scss"
import SaleBanner from "../../components/SaleBanner";
import OfferingFactors from "../../components/OfferingFactors";
import Categories from "../../components/CategoriesSection";
import ItemsSection from "../../components/ItemsSection";
import FilterSection from "../../components/FilterProductSection";
import BlogSection from "../../components/BlogsSection";
import ReviewsSection from "../../components/ReviewsSection";
import Slider from "../../components/HomePage/Landing/Slider";
import BottomMenusMobile from "../../components/BottomMenusMobile";

const KeyFeatures = lazy(() => import("../../components/HomePage/KeyFeatures"));
const Clients = lazy(() => import("../../components/ClientsSection"));
const Products = lazy(() => import("../../components/HomePage/Products"));
const Services = lazy(() => import("../../components/HomePage/Services"));
const Video = lazy(() => import("../../components/HomePage/Video"));
const Info = lazy(() => import("../../components/HomePage/Info"));
const Download = lazy(() => import("../../components/HomePage/Download/Download"));
const Footer = lazy(() => import("../../components/Footer"));

const Homepage = ({ history }) => {
  const dispatch = useDispatch();
  const { bestSelling, updatedAt, hotSelling, newLaunching, saleBanner, banners } = useSelector(state => state.home);

  useEffect(() => {
    dispatch(getBestSellingProducts(bestSelling?.length, updatedAt));
    dispatch(getHotSellingProducts(hotSelling?.length));
    dispatch(getNewLaunchingProducts(newLaunching?.length));
    dispatch(getSaleBannerData(saleBanner?.content));
    dispatch(getBanners(banners?.desktop?.length && banners?.mobile?.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderHelmet() {
    return <Helmet>
      <meta charSet="utf-8" />
      <title>Pintar</title>
    </Helmet>;
  }
  


  return (
    <div className="home-page">
      {renderHelmet()}
      <Navbar />
      <div className="navbar-placeholder"></div>
      <Slider banners={banners} />
      <div className="homepage-content-container">
        <div className="homepage-content">
          <Suspense fallback={<div />}>
            <ItemsSection headingProps={{preText: '🔥 Hot ', boldText:'Sale!'}} data={hotSelling} />
          </Suspense>
          <Suspense fallback={<div />}>
            <Download />
          </Suspense>
          <Suspense fallback={<div />}>
            <FilterSection />
          </Suspense>
          <Suspense fallback={<div />}>
            <OfferingFactors />
          </Suspense>
          <Suspense fallback={<div />}>
            <Categories />
          </Suspense>
          <Suspense fallback={<div />}>
            <ItemsSection headingProps={{preText:'⭐ Best Selling ', boldText:'Products!'}} data={bestSelling} />
          </Suspense>
          <Suspense fallback={<div />}>
            <SaleBanner data={saleBanner} />
          </Suspense>
          <Suspense fallback={<div />}>
            <ItemsSection headingProps={{preText:'💥 New ', boldText:'Launches!'}} data={newLaunching} />
          </Suspense>
          <Suspense fallback={<div />}>
            <ReviewsSection />
          </Suspense>
          <Suspense fallback={<div />}>
            <BlogSection />
          </Suspense>
        </div>
      </div>
      <Suspense fallback={<div />}>
        <Clients />
      </Suspense>
      <div className="homepage-content-container" style={{paddingBottom: '100px'}}>
        <div className="homepage-content bottom-section-homepage">
          <Suspense fallback={<div />}>
            <Video />
          </Suspense>
        </div>
      </div>
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
      {/* <Suspense fallback={<div />}>
        <BottomMenusMobile />
      </Suspense> */}
    </div>
  );
};

export default Homepage;
