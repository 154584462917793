import React, { useEffect, useState } from "react";
import Button from "../../Button";
// import image1 from "../../../assets/HomePage/Landing/Image 1@2x-min.png";
// import image2Desktop from "../../../assets/HomePage/Landing/Image 2@2x-min.png";
// import image3Desktop from "../../../assets/HomePage/Landing/Image 3@2x-min.png";
// import image4Desktop from "../../../assets/HomePage/Landing/Image 4@2x-min.png";
// import image2Mobile from "../../../assets/HomePage/Landing/Image 2-mobile.jpg";
// import image3Mobile from "../../../assets/HomePage/Landing/Image 3-mobile.jpg";
// import image4Mobile from "../../../assets/HomePage/Landing/Image 4-mobile.jpg";
import image1LQ from "../../../assets/HomePage/Landing/Image1@2x-low.png";
import image2LQDesktop from "../../../assets/HomePage/Landing/Image2@2x-low.png";
import image3LQDesktop from "../../../assets/HomePage/Landing/Image3@2x-low.png";
import image4LQDesktop from "../../../assets/HomePage/Landing/Image4@2x-low.png";
import image2LQMobile from "../../../assets/HomePage/Landing/Image2-mobile-low.jpg";
import image3LQMobile from "../../../assets/HomePage/Landing/Image3-mobile-low.jpg";
import image4LQMobile from "../../../assets/HomePage/Landing/Image4-mobile-low.jpg";
import Img from '../../Img';
import { prepareAssetsFolderUrl } from "../../../shared/getProductImageUrl";

import HeroBg from "../../../assets/images/hero-section-bg.png";

const getAssetsUrl = prepareAssetsFolderUrl("home-page%2Flanding");

const Landing = ({ history }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', handleWidth);
    handleWidth();
    return () => {
      window.removeEventListener('resize', handleWidth);
    }
  }, []);

  const handleWidth = () => {
    const currentWidth = window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    setWidth(currentWidth);
  };

  let image2 = getAssetsUrl('Image2@2x-min.png');
  let image2LQ = image2LQDesktop;

  let image3 = getAssetsUrl('Image3@2x-min.png');
  let image3LQ = image3LQDesktop;

  let image4 = getAssetsUrl('Image4@2x-min.png');
  let image4LQ = image4LQDesktop;

  if (width < 768) {
    image2 = getAssetsUrl('Image2-mobile.jpg');
    image2LQ = image2LQMobile;

    image3 = getAssetsUrl('Image3-mobile.jpg');
    image3LQ = image3LQMobile;

    image4 = getAssetsUrl('Image4-mobile.jpg');
    image4LQ = image4LQMobile;
  }

  return (
    <div className="home-page-landing">
      <img src={HeroBg} className="hero-section-bg" />
      {/* <div className="left-side">
        <p className="heading">Resourceful Living with Smart System</p>
        <p className="text">
          Introducing the smart living system for your home. With PINTAR’s latest technological solutions, upgrade your lifestyle and enjoy the luxury of an automated home.
        </p>
        <Button className="button" onClick={() => history.push("/shop/6gkSICnXAzNBlQWPjPuZ")}>Shop Now</Button>
      </div>
      <div className="right-side">
        <div className="right-side-first-div">
          <div className="image-1-container">
            <Img srcLQ={image1LQ} srcHQ={getAssetsUrl('Image1@2x-min.png')} alt="" />
          </div>
          <div className="image-2-container">
            <Img srcLQ={image2LQ} srcHQ={image2} alt="" />
          </div>
        </div>
        <div>
          <div className="image-3-container">
            <Img srcLQ={image3LQ} srcHQ={image3} alt="" />
          </div>
          <div className="image-4-container">
            <Img srcLQ={image4LQ} srcHQ={image4} alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Landing;
