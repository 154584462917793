import React, { useState } from "react";
import "./review-card.scss";
import { ReactComponent as StarFilled } from '../../../assets/Icons/star-filled.svg';
import { ReactComponent as StarOutlined } from '../../../assets/Icons/star-outlined.svg';

import { Link } from "react-router-dom";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M9.62004 4.45337L13.6667 8.50004L9.62004 12.5467M2.33337 8.50004H13.5534" stroke="#D5AC4F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
  };

const ReviewCard = (({msg,star, name,date,img,title}) => {
    return (
      <div className="review-card-box">
        <div className="review-stars">
          <StarFilled />
          <StarFilled />
          <StarFilled />
          <StarFilled />
          {star === 5 ? <StarFilled /> : <StarOutlined />}
        </div>
        <div className="review-msg">{msg}</div>
        <div className="user-details-n-date">
          <div className="name-n-date">
            <div className="review-name">{name}</div>
            <div className="review-date">{date || title}</div>
          </div>
          <div className="user-img"><img src={img} /></div>
        </div>
      </div>
    );
}
);

export default ReviewCard;