import React, { useEffect, useRef, useState } from "react";
import "./item-card.scss";
import { ReactComponent as Star } from "../../../assets/Icons/rate-star.svg";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Cart } from "../../../assets/HomePage/Products/shopping-cart.svg";
import { addCartItem } from "../../../containers/CartPage/Cart.action";
import isProductAlreadyInCart from "../../../shared/isProductAlreadyInCart";
import showToast from "../../../shared/showToast";

import TempImage from "../../../assets/default-image.png";
import { getProductImageUrl } from "../../../shared/getProductImageUrl";

import {useHistory} from "react-router-dom"
import { addToCartEvent, viewProductEvent } from "../../../utils/facebookPixelEvents";

const ProductCard = (({data}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const firstTimeRenderRef = useRef(true);


  const cartItems = useSelector(state => state.cart.cartItems);

  const { id, name, description, moreOptions, avgRating, reviewsCount, available, dropdownLabel, discAmountOnQty, discOnQty, info: { id: colorId, type, colorName, hex, images, weight, price, discountPercentage } } = data;
  const alreadyInCart = isProductAlreadyInCart(cartItems, { id, colorId, typeId: type.id });
  const disableActions = alreadyInCart || !available;
  const dispatch = useDispatch();
  const history = useHistory();

  const productPrice = !!discountPercentage ? price - (price / 100 * discountPercentage) : price;

  const hanleAddToCart = e => {
    e.stopPropagation();
    if (alreadyInCart) return;
    if (cartItems.find(i => i.id === id && i.type.id === type.id && i.hex === hex)) {
      return showToast("Error", "Product is already in the cart", true)
    }

    addToCartEvent({id, name});
    
    dispatch(addCartItem({ colorId, id, image: images[0], name, colorName, weight, discAmountOnQty, discOnQty, hex, price: productPrice, quantity: 1, type, typeLabel: dropdownLabel }));
    showToast("Success", "Product added to the cart", true);
  }





  useEffect(() => {
    if (firstTimeRenderRef.current) {
      firstTimeRenderRef.current = false;
    } else {
      setIsLoaded(false);
    }
  }, [images[0]]);

  const handleOnImageLoaded = () => {
    setIsLoaded(true);
  }
  
  return (
      <div className="item-card-box" onClick={(e) => {
        viewProductEvent({id, name});
        e.stopPropagation();
        console.log('location: ', `/product/${id}`);
        history.push(`/product/${id}/`);
        window.scrollTo(0, 0);
        return;
      }}>
        <div className="img-container">
          <img src={isLoaded ? getProductImageUrl(images[0]) : TempImage} onLoad={handleOnImageLoaded} style={isLoaded ? { visibility: 'visible' } : { visibility: 'hidden', width: 0, height: 0 }} />
          {!!data.tag && <div className="item-card-tag">{data.tag}</div>}
        </div>
        <div className="info-container">
          <div className="card-product-title">
            {name}
          </div>
          <div className="card-product-price">
            <div className="price">Rs.{Math.round(productPrice)?.toLocaleString()}</div>
            {!!discountPercentage && <div className="old-price" style={{ textDecoration: "line-through" }}>Rs.{price?.toLocaleString()}</div>}
            {!!discountPercentage && <div className="discount">{discountPercentage}% off</div>}
          </div>
          <div className="card-rating-n-btn">
            <div className="rating" style={{fontSize: data.rating ? '14px' : '12px'}}>
              {!!reviewsCount && <Star />}
              {!!reviewsCount ? Number.parseFloat(avgRating).toFixed(1) : 'Be first to review'}
            </div>
            <div style={disableActions ? { cursor: 'auto', pointerEvents: 'none', opacity: '0.5'} : {}} className="add-cart-btn" onClick={hanleAddToCart}>Add To Cart</div>
          </div>
        </div>
      </div>
    );
}
);

export default ProductCard;