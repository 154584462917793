import { makeStyles } from "@material-ui/core/styles";
import colors from "../../shared/colors";

const useStyles = makeStyles({
  button: {
    fontSize: "1.4rem",
    fontFamily: ["Poppins", "sans-serif"],
    padding: ".8rem 2.4rem",
    textTransform: "none",
  },
  primary: {
    color: colors.darkGrey1,
    fontWeight: "600",
  },
  secondary: {
    color: colors.white,
  },
});

export default useStyles;
