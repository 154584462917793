import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "./blogs-section.scss";
import blogCover1 from "../../assets/images/blog1.png";
import blogCover2 from "../../assets/images/blog2.png";
import blogCover3 from "../../assets/images/blog3.png";


import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import BlogCard from "./BlogCard";

const data = [
  {
    title: "Covered Strip Light in Todays Architecture",
    label: "Mood Controlled Lighting",
    author: "Ali Mehmood",
    readTime: "4",
    cover: blogCover1,
    labelBg: '#E0EDF3',
  },
  {
    title: "Is Making Life Ease for Our Elders Possible?",
    label: "Interactive Home",
    author: "Sunia Shakeel",
    readTime: "7",
    cover: blogCover2,
    labelBg: '#FEEDED',
  },
  {
    title: "The New Era of Lighting your Stations",
    label: "Mood Controlled Lighting",
    author: "Junaid Safder",
    readTime: "3",
    cover: blogCover3,
    labelBg: '#E0EDF3',
  },
];

// {headingProps, data = [...Array(6).keys()]}

const BlogSection = () => {
  // We will use React useRef hook to reference the wrapping div:
  const containerRef = useRef(null);
  // Now we pass the reference to the useDraggable hook:
  const { events } = useDraggable(containerRef, {isMounted: true});

  return (
    <div className="blogs-section-container">
      <SectionHeading preText={'🗞️ Some Exciting '} boldText='Blogs' posText=' from our team' isBlogSec />
      <div className="blogs-cards-section" {...events} ref={containerRef}>
      {
        // repeat items just to fill container and make its content overflow
        data.map((blog, i) => (
          <BlogCard {...blog} key={i} />
        ))
      }
    </div>
    </div>
  );
};

export default BlogSection;
