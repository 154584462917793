export const getProductImageUrl = (id) =>
  `https://firebasestorage.googleapis.com/v0/b/pintar-774c3.appspot.com/o/product-images%2F${id}?alt=media&token=e4e13723-3114-4af1-8a40-523e810d8375`;

export const getProductBrochureUrl = (id) =>
  `https://firebasestorage.googleapis.com/v0/b/pintar-774c3.appspot.com/o/product-brochure%2F${id}?alt=media&token=e4e13723-3114-4af1-8a40-523e810d8375`;

export const getReviewImageUrl = (id) =>
  `https://firebasestorage.googleapis.com/v0/b/pintar-774c3.appspot.com/o/review-image%2F${id}?alt=media&token=e4e13723-3114-4af1-8a40-523e810d8375`;

export const getBlogImageUrl = (id) =>
  `https://firebasestorage.googleapis.com/v0/b/pintar-774c3.appspot.com/o/blog-images%2F${id}?alt=media&token=e4e13723-3114-4af1-8a40-523e810d8375`;

export const getSmURL = (src) => {
  const srcSplit = src.split(".");
  return `${srcSplit[0]}-sm.${srcSplit[1]}`;
};

export const prepareAssetsFolderUrl = folderName => path => `https://firebasestorage.googleapis.com/v0/b/pintar-774c3.appspot.com/o/assets%2F${folderName}%2F${path}?alt=media&token=2d915a9c-4e65-41e1-83e5-6cf17012fe00`;