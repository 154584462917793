import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "./filter-product-section.scss";
import { ReactComponent as Arrow } from "../../assets/Icons/arrow.svg";


import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import FilterCard from "./FilterCard";

import img1 from "../../assets/images/filter-product/filter-1.png";
import img2 from "../../assets/images/filter-product/filter2.png";
import img3 from "../../assets/images/filter-product/filter3.png";
import img4 from "../../assets/images/filter-product/filter4.png";

const data = [
  {
    title: "Smart Switchboards",
    color: "#E0EDF3",
    img: img1,
    id: "WVXvl0PaPZSxISPZSvou"
  },
  {
    title: "Smart RGB Lighting",
    color: "#FEEDED",
    img: img2,
    id: "a48Iah09J3n16UVcScvL"
  },
  {
    title: "Smart Plugs",
    color: "rgba(241, 233, 214, 0.90)",
    img: img3,
    id: "aMnki6P5P7PQMNJJTfEM"
  },
  {
    title: "Smart Cameras",
    color: "rgba(224, 243, 228, 0.90)",
    img: img4,
    id: "bWISg2n2FG9rsLuPlcKk"
  },
];

// {headingProps, data = [...Array(6).keys()]}

const FilterSection = () => {
  // We will use React useRef hook to reference the wrapping div:
  const containerRef = useRef(null);
  // Now we pass the reference to the useDraggable hook:
  const { events } = useDraggable(containerRef, {isMounted: true});

  return (
    <div className="items-section-container">
      <SectionHeading preText={'♥️ Filter the '} boldText='Product you love!' showFilter />
      <div className="items-cards-section" {...events} ref={containerRef}>
      {
        // repeat items just to fill container and make its content overflow
        data.map((data, i) => (
          <FilterCard title={data.title} bgColor={data.color} img={data.img} key={data.id} filterId={data.id} />
        ))
      }
    </div>
    </div>
  );
};

export default FilterSection;
