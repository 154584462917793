import React from "react";
import "./offering-factors.scss";
import { ReactComponent as Card } from "../../assets/Icons/factors/card-payment.svg";
import { ReactComponent as Delivery } from "../../assets/Icons/factors/delivery.svg";
import { ReactComponent as Replacement } from "../../assets/Icons/factors/replacement.svg";
import { ReactComponent as Warranty } from "../../assets/Icons/factors/waranty.svg";
import { ReactComponent as Support } from "../../assets/Icons/factors/support.svg";

const OfferingFactors = () => {

  return (
    <div className="offering-factors">
      <div className="offering-factor">
        <Warranty />
        <div className="bold">1 Year</div>
        <div>Warranty</div>
      </div>
      <div className="offering-factor">
        <Replacement />
        <div className="bold">7 Days</div>
        <div>Replacement</div>
      </div>
      <div className="offering-factor">
        <Delivery />
        <div className="bold">Delivery</div>
        <div>Free & Fast</div>
      </div>
      <div className="offering-factor">
        <Support />
        <div className="bold">9 AM - 5 PM</div>
        <div>Support</div>
      </div>
      <div className="offering-factor">
        <Card />
        <div className="bold">Card Payment</div>
        <div>Available</div>
      </div>
    </div>
  );
};

export default OfferingFactors;
