import React from "react";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import stylesheet from "./Button.styles";
import colors from "../../shared/colors";

const CustomButton = ({
  children,
  type = "primary",
  variant = "contained",
  styles,
  onClick,
  htmlType,
  className = "",
  disabled = false,
  icon,
}) => {
  const classes = stylesheet();

  return (
    <ThemeProvider theme={createMuiTheme({
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: colors.darkYellow1,
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          light: "#0066ff",
          main: colors.darkGrey1,
          // dark: will be calculated from palette.secondary.main,
          contrastText: "#ffcc00",
        },
      },
    })}>
      <Button
        variant={variant}
        color={type}
        className={`${classes[type]} ${classes.button} ${className}`}
        classes={{ containedPrimary: classes.containedPrimary }}
        style={styles}
        disableElevation
        type={htmlType}
        onClick={onClick}
        disabled={disabled}
        startIcon={icon}
      >
        {children}
      </Button>
    </ThemeProvider >
  );
};

export default CustomButton;
