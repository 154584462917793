import React, { useState } from "react";
import "./section-heading.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as PintarIcon } from "../../assets/Icons/pintar-icon.svg";
import { ReactComponent as Android } from "../../assets/Icons/android-icon.svg";
import { ReactComponent as Apple } from "../../assets/Icons/apple-icon.svg";
import { ReactComponent as Filter } from "../../assets/Icons/filter-icon.svg";

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M14.43 5.93018L20.5 12.0002L14.43 18.0702M3.5 12.0002H20.33" stroke="#D5AC4F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

const ArrowBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke="#191A23" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
const ArrowForward = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M14.43 5.93018L20.5 12.0002L14.43 18.0702M3.5 12.0002H20.33" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

const SectionHeading = ({
  preText,
  boldText,
  posText,
  style,
  showPintarIcon = false,
  showFilter = false,
  isBlogSec = false,
  reviewSection = false,
  hideTextClients = false,
}) => {
  const playstoreLink = "https://play.google.com/store/apps/details?id=pintarautomation.pk";
  const appstoreLink = "https://apps.apple.com/pk/app/pintar/id1557535423";

  const [disableBtnNext, setDisableBtnNext] = useState(false);
  const [disableBtnPrev, setDisableBtnPrev] = useState(true);

  const history = useHistory();

  return (
    <div className={`main-section-heading-container${showFilter || isBlogSec || reviewSection ? ' with-filter' : ''}${reviewSection ? ' review-section-heading' : ''}${isBlogSec ? ' blog-section-heading' : ''}`}>
      <div className="new-section-heading" style={style}>
        <div className={`section-heading-text${showPintarIcon ? ' pintar-icon' : ''}`}>
          {preText} <span className="bold-section-heading">{boldText}</span><span className={`${hideTextClients ? 'hide-text-heading' : ''}`}>{posText}</span>
        </div>
      </div>
      {showPintarIcon && <div className="app-download-text">
        You can download the <span>Pintar App</span> from <a target={'_blank'} href={playstoreLink} >here</a>, {<Android />} If you are an <span>Android</span> user, and if you are an <span>iPhone</span> user, <a target={'_blank'} href={appstoreLink} >click here</a> {<Apple />}
      </div>}
      {showFilter && <div className="filter-btn" onClick={() => {
        history.push("/shop/6gkSICnXAzNBlQWPjPuZ");
        window.scrollTo(0, 0);
      }}>
        All Products {<Filter />}
      </div>}
      {isBlogSec && <div className="browse-btn">
        Browse All {<Arrow />}
      </div>}
      {reviewSection && <div className="reviews-slide-btns">
        <div className={`review-slider-btn`} style={{background: '#fff'}} onClick={() => {
          const element = document.querySelector('.blogs-cards-section');
          element.style['scroll-behavior'] = 'smooth';
          element.scrollBy(-500, 0);
          // setDisableBtnNext(false);
          // setDisableBtnPrev(true);
          element.style['scroll-behavior'] = 'auto';
        }}>
          <ArrowBack />
        </div>
        <div className={`review-slider-btn`} onClick={() => {
          const element = document.querySelector('.blogs-cards-section');
          element.style['scroll-behavior'] = 'smooth';
          document.querySelector('.blogs-cards-section').scrollBy(500, 0);
          // setDisableBtnNext(true);
          // setDisableBtnPrev(false);
          element.style['scroll-behavior'] = 'auto';
        }}>
          <ArrowForward />
        </div>
      </div>}
    </div>
  );
};

export default SectionHeading;
