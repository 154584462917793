import React, { useEffect, useState } from "react";

const CustomImg = ({ srcLQ, srcHQ, alt = "", onLoad, runItBefore = false, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [localSrcHQ, setLocalSrcHQ] = useState("");

  useEffect(() => {
    setLocalSrcHQ(srcHQ);
    return () => {
      setLocalSrcHQ("");
    }
  }, [srcHQ]);

  const handleOnImageLoaded = () => {
    if (onLoad && runItBefore) onLoad();
    setIsLoaded(true);
    if (onLoad && !runItBefore) onLoad();
  }

  return srcLQ?.trim() && srcHQ?.trim()
    ? <>
      <img
        src={localSrcHQ}
        alt={alt}
        onLoad={handleOnImageLoaded}
        style={isLoaded ? { visibility: 'visible' } : { visibility: 'hidden', width: 0, height: 0 }}
        className={className}
      />
      {isLoaded
        ? null
        : <img src={srcLQ} alt="" className={className} />}
    </>
    : <img src={srcLQ || srcHQ} alt={alt} onLoad={onLoad} className={className} />;
};

export default CustomImg;
