import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./slider.scss";

const Slider = ({banners}) => {


  const [activeSlide, setActiveSlide] = useState(0);
  const slideRef = useRef(0);
  const bannersRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();

    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth <= 500) {
          setIsMobile(true)
        } else {
          setIsMobile(false)
      }
    }

    useEffect(() => {
      bannersRef.current = banners;
    }, [banners.desktop])

    // create an event listener
    useEffect(() => {
        if (window.innerWidth <= 500) {
            setIsMobile(true)
          } else {
            setIsMobile(false)
        }
        
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      }
    }, [])

    const next = () => {
      if(!bannersRef?.current?.desktop?.length) return;

        if(slideRef.current === bannersRef?.current?.desktop?.length - 1) {
            setActiveSlide(0);
            slideRef.current = 0;
        }
        else {
            setActiveSlide(slideRef.current + 1);
            slideRef.current = slideRef.current + 1;
        }
    }
    const prev = () => {
      if(!bannersRef?.current?.desktop?.length) return;

        if(slideRef.current === 0) {
            setActiveSlide(bannersRef?.current?.desktop?.length - 1);
            slideRef.current = bannersRef?.current?.desktop?.length -1;
        }
        else {
            setActiveSlide(slideRef.current - 1);
            slideRef.current = slideRef.current - 1;
        }
    }

    const autoSlide = () => {
        setInterval(() => {
            next();
        }, 5000); // slide speed = 3s
     }
    
     useEffect(() => {
        const timer = setInterval(() => {
            next();
        }, 5000);

        return () => {
            clearInterval(timer);
        }
     }, [])

    return (
        <div className="carousel">
      <div className="carousel_inner">
        {(isMobile ? banners?.mobile : banners?.desktop)?.map((slide, i) => (
            <div className={`carousel_item${activeSlide === i ? ' carousel_item__active' : ''}`}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/pintar-774c3.appspot.com/o/banners%2F${slide}?alt=media&token=e4e13723-3114-4af1-8a40-523e810d8375`} alt="" className="carousel_img" />
         </div>
        ))}
      </div>
            <div className="carousel_caption">
               <div className="carousel_shop_now_btn" onClick={(e) => {
                e.stopPropagation();
                history.push("/shop/6gkSICnXAzNBlQWPjPuZ/");
               }}>Shop Now</div>
            </div>
    <div className="carousel_indicator">
        {(isMobile ? banners?.mobile : banners?.desktop)?.map((_,i) => (<button onClick={() => {setActiveSlide(i)}} className={`carousel_dot${activeSlide === i ? ' carousel_dot__active' : ''}`}></button>))}
    </div>

      <div className="carousel_control">
         <div onClick={prev} className="carousel_button carousel_button__prev">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.57 5.92999L3.5 12L9.57 18.07M20.5 12H3.67" stroke="#191A23" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
         </div>
         <div onClick={next} className="carousel_button carousel_button__next" style={{background: '#191A23'}}>
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M14.43 5.92999L20.5 12L14.43 18.07M3.5 12H20.33" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
         </div>
      </div>
   </div>
    );
}

export default Slider;