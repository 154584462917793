import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "./categories-section.scss";
import { ReactComponent as Arrow } from "../../assets/Icons/arrow.svg";
import { useHistory } from "react-router-dom";

const Categories = () => {
  const history = useHistory();

  return (
    <div className="categories-section">
      <SectionHeading preText='💡 Smart life with' boldText='smart categories' />
      <div className="categories-cards">
        <div className="categories-card-item-1">
          <div className="categories-card-text">
            Life's a little easier with a <span className="bold">Interactive Home</span>
            <div className="category-nav-btn" onClick={() => {
              history.push("/shop/6gkSICnXAzNBlQWPjPuZ");
              window.scrollTo(0, 0);
            }}>Shop Now <Arrow /></div>
          </div>
        </div>
        <div className="categories-card-item-2">
          <div className="categories-card-text" style={{color: "#fff"}}>
            Enrich your Experience with <span className="bold">Mood Controlled Lighting</span>
            <div className="category-nav-btn" style={{color: "#292D32", background: "#fff"}} onClick={() => {
              history.push("/shop/pFAxb6pdGq6WHsZKAykP");
              window.scrollTo(0, 0);
            }}>Shop Now <Arrow /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
