import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "./item-section.scss";
import { ReactComponent as Arrow } from "../../assets/Icons/arrow.svg";


import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import ProductCard from "./ItemCard";

function Box({ value }) {
  return (
    <div className="dummy-box">
    </div>
  );
}

const Categories = ({headingProps, data=[]}) => {
  // We will use React useRef hook to reference the wrapping div:
  const containerRef = useRef(null);
  // Now we pass the reference to the useDraggable hook:
  const { events } = useDraggable(containerRef, {isMounted: true});


  return (
    <div className="items-section-container" style={{display: !!data?.length ? 'block' : 'none'}}>
      <SectionHeading {...headingProps} />
      <div className="items-cards-section" {...events} ref={containerRef}>
      {
        data.map((data, i) => (
          <ProductCard key={i} data={data} />
        ))
      }
    </div>
    </div>
  );
};

export default Categories;
